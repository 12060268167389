<template>
  <div>
    <span
      class="companyName"
      :style="{color:C_color}"
      >深圳市晶准通信技术有限公司
    </span><br/>
    <span
      class="englishName"
      :style="{color:E_color}"
    >
      Shenzhen Jingzhun Communication Technology Co., Ltd
    </span>
  </div>
</template>

<script>
export default {
    name:"CompanyName",
    props:["C_color","E_color"]
}
</script>

<style scoped>
    .companyName{
        font-size: min(2.2vw,24px);
        font-weight: 700;
        line-height: 170%;
    }
    .englishName{
        font-size: min(1vw,12px);
        color:rgb(128, 128, 128);
        line-height: 150%;
        display: block;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
    }
</style>