import VueRouter from "vue-router";

const router = new VueRouter({
    mode:'history',
    routes:[
        {
            path:'/',
            component:()=>import('../pages/Homepage'),
            meta:{title:'首页'}
        },
        {
            path:'/human-resource',
            component:()=>import('../pages/HumanResource'),
            meta:{title:'人力资源'}
        },
        {
            path:'/solution-product/:id',
            component:()=>import('../pages/SolutionAndProduct'),
            meta:{title:'产品'},
            props($route){
                return {
                    id: $route.params.id,
                }
            }
        },
        {
            path:'/news',
            component:()=>import('../pages/News'),
            meta:{title:'新闻中心'},
        },
        {
            path:'/contact-us',
            component:()=>import('../pages/ContactUs'),
            meta:{title:'联系我们'}
        },
        {
            path:'/product',
            component:()=>import('../pages/Product'),
            meta:{title:'产品中心'},
            children:[
                {
                    name:'notFound',
                    path:'not-found',
                    meta:{title:'找不到该产品'},
                    component:()=>import('../components/common/WithoutContent')
                },{
                name:'detail',
                path:':id',
                meta:{title:'产品详情'},
                component:()=>import('../pages/ProductDetail'),
                props($route){
                    return{
                        id:$route.params.id
                    }
                }
            }
            ]
        },
        {
            path:'/news/detail/:id',
            meta:{title:'新闻详情'},
            component:()=>import('../pages/NewsDetail'),
            props($route){
                return {
                    id: $route.params.id
                }
            }
        }
    ]
})

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}

export default router;