import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router/index'
import animated from 'animate.css'
import http from './utils/http'

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(animated)
Vue.prototype.$axios = http

new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
