import axios from 'axios'

const service=axios.create({
  baseURL:"https://www.rfarray.com:5000/api",
  timeout:1000000,
})

service.interceptors.response.use(
  res => {
    return res.data.data
  },
  err => {
    return Promise.reject(err)
  }
)

export default service;
