import service from './service'

const http = {
  get(url,params){
    const config = {
      method:'get',
      url:url
    }
    if(params) config.params = params
    return service(config)
  },
  post(url,params){
    const config = {
      method:'post',
      url:url
    }
    if(params) config.params = params
    return service(config)
  }
}

export default http