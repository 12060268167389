<template>
  <div id="header">
    <div class="left">
        <div>
            <img
                id="logo"
                src="https://pic.imgdb.cn/item/63ae64ed08b6830163e08f7f.png"
            />
        </div>
        <div
            class="headerCompany"
            style="margin-left: 2%;padding-right: 5%;"
        >
            <companyName />
        </div>
    </div>
    <div class="headerSearch">
        <div style="width:100%">
            <el-input
                placeholder="请输入关键字"
                v-model="search.searchWord"
                @keydown.enter.native="handleClick"
            >
            <i
                slot="prefix"
                class="el-input__icon el-icon-search"
            > </i>
            </el-input>
        </div>
        <el-button
            style="
                background-color : rgb(46, 84, 151);
                color:white;
                height: 40px;
                margin-left: 1%;
            "
            @click="handleClick"
        >
        搜索
        </el-button>
    </div>
  </div>
</template>

<script>
import companyName from './companyName'
export default {
    name:"Title",
    components:{companyName},
    data(){
        return {
            search:{
                searchWord:''
            }
        }
    },
    methods:{
        searchProduct(query){
            this.$axios.get(`/search/searchProduct?search=${query}`)
                .then((res)=>{
                    this.$set(this.search,'searchWord','')
                    if(this.$route.name === 'notFound' && !res[0]) return
                    if(!res[0]){
                        this.$router.push(`/product/not-found`)
                        return
                    }
                    const currentId = res?.[0]?.id
                    const beforeId = this.$route.params?.id
                    if(beforeId == currentId) return
                    this.$router.push(`/product/${currentId}`)
                })
        },
        handleClick(){
            this.searchProduct(this.search.searchWord)
        },
        handleKeyDown(){
            this.searchProduct(this.search.searchWord)
        }
    }
}
</script>

<style scoped>
    #header{
        display:flex;
        height: 100%;
        max-width: 1200px;
        margin: auto;
        justify-content: space-between;
    }
    #logo{
        height:100%;
    }
    .left{
        display: flex;
        width: 40%;
    }
    .headerSearch{
        width: 50%;
        display: flex;
        padding-top: 1.5%;
    }
    @media (max-width:640px) {
        #logo{
            height:48px;
            width: 48px;
        }
        .headerSearch{
            width: 100%;
        }
        .left{
            width: 100%;
        }
        #header{
            flex-direction: column;
        }
        .headerCompany{
            width: 70% !important;
        }
    }
   
</style>