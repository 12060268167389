<template>
    <div style="width:100%;background-color: rgb(23, 54, 93);" ref="BottomRef">
        <div id="bottom">
          <div id="bottomCompany">深圳市晶准通信技术有限公司  版权所有</div>
            <div class="two_box">
              <div>
                <a href="https://beian.miit.gov.cn" target="_blank" class="link">
                  粤ICP备2023027315号-1222
                </a >
              </div>
              <div class="PSD_Box">
                <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009889" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                  <img src="../assets/PSD.png" style="float:left;"/>
                  <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
                    粤公网安备 44030502009889号
                  </p >
                </a >
              </div>
            </div>

            <div class="one_box">
              <div>
                <a href="https://beian.miit.gov.cn" target="_blank" class="link">
                  粤ICP备2023027315号-1222
                </a >
              </div>
              <div class="PSD_Box">
                <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502009889" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                  <img src="../assets/PSD.png" style="float:left;"/>
                  <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
                    粤公网安备 44030502009889号
                  </p >
                </a >
              </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
name:'BottomBox',
data(){
  return{
    pageData:{
      bottomWidth:null
    }
  }
},
methods:{
  handleClick(){
    window.location = 'https://beian.miit.gov.cn'
  }
}
}
</script>

<style scoped>
  .link{
    color: rgb(143, 170, 220);
    text-decoration-line: none;
  }
  .PSD_Box{
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
  #bottom{
    width: 100%;
    height: 41px;
    max-width: 1200px;
    margin: auto;
    display: flex;
    color: rgb(143, 170, 220);
    justify-content: space-between;
    line-height: 41px;
    font-size: 14px;
    background-color: rgb(23, 54, 93);
    box-sizing: border-box;
    padding: 0 40px;
  }
  .one_box{
    display: none;
  }
  .two_box{
    display:flex;
  }
  @media (max-width:810px) {
    .two_box{
      display:none;
    }
    .one_box{
      display: flex;
      flex-direction: column;
      text-align: left;
    }
    #bottom{
      height: unset;
      display: flex;
      align-items: center;
    }
    .PSD_Box{
      margin-left: unset;
      margin-bottom: 12px;
    }
    #bottomCompany{
      font-size:18px
    }
  }
  @media (max-width:640px) {
    #bottom{
      font-size: 12px;
      display: flex;
      justify-content: space-between;
    }
    #bottomCompany{
      display: none;
    }
    .one_box{
      width: 100%;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .PSD_Box{
      margin-bottom: unset;
    }
  }
  @media (max-width:460px) {
    .one_box{
      flex-direction: column;
    }
    .PSD_Box{
      margin-bottom: 12px;
    }
  }
</style>