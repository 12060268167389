<template>
  <div id="footer">
    <div id="main">
        <div style="width:35%" class="footerCompany">
          <img style="width:68px;" src="https://pic.imgdb.cn/item/63ae64ed08b6830163e08f96.png" />
          <companyName C_color="#ffffff" E_color="rgb(211, 211, 211)" />
        </div>
        <div class="bottomNav" v-for="(item,index) in Nav" :key="index">
          <h2>{{item.title}}</h2>
          <p class="bottomNavChild" v-for="i in item.children" :key="i.id">{{i.name}}</p>
        </div>
        <div class="footerCompany" style="display:flex;justify-content:center;align-items:center;">
          <img
            src="https://pic.imgdb.cn/item/63ae64ed08b6830163e08f33.png"
          />
        </div>
      </div>
  </div>
</template>

<script>
import companyName from './companyName'
export default {
    name:"Footer",
    components:{companyName},
    data(){
      return{
        Nav:[
          {
            title:"关于我们",
            children:[
              {id:"1",name:"企业简介"},
              {id:"2",name:"企业文化"},
            ]
          },
          {
            title:"人力资源",
            children:[
              {id:"3",name:"人才战略"},
              {id:"4",name:"人才招聘"},
            ]
          },
          {
            title:"新闻中心",
            children:[
              {id:"5",name:"公司新闻"},
              {id:"6",name:"行业新闻"},
            ]
          }
      ]
      }
    }
}
</script>

<style scoped>
  #footer{
    display: flex;
    width: 100%;
    background-color:rgb(46, 84, 151);
    margin-top: 50px;
  }
  #main{
    background-color:rgb(46, 84, 151);
    width: 100%;
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    padding-top: 2.9%;
    padding-bottom: 2.6%;
    margin: auto;
    text-align: center;
  }
  #upper{
    display: flex;
    justify-content: space-around;
  }
  .bottomNav{
    padding-top: 20px;
    color:white;
    font-size: min(1.0vw,20px);
  }
  .bottomNavChild{
    margin: 8px 0 8px 0 ;
  }
  @media (max-width:640px) {
    .footerCompany{
      display:none !important;
    }
  }
</style>