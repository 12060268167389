<template>
  <div id="app">
    <el-container>
      <el-header style="height:108px"><Title /></el-header>
      <el-main>
        <TabBar />
        <router-view></router-view>
      </el-main>
      <el-footer style="height:unset">
        <Footer />
        <Bottom />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Title from './components/Title'
import TabBar from './components/TabBar'
import Footer from './components/Footer'
import Bottom from './components/Bottom'
export default {
  name: 'App',
  components: {
    Title,
    TabBar,
    Footer,
    Bottom
  }
}
</script>

<style scoped>
.el-header{
  overflow:hidden;
  padding: 20px 0;
}
.el-main{
  padding: 0;
  overflow: hidden;
}
.el-footer{
  padding: 0;
}
@media (max-width:640px){
  .el-header{
    padding: 5px 0;
  }
}
</style>
